import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { FuseAlertDialogComponent } from './alert-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { IconModule } from '@tinc/components/icon/icon.module';

@NgModule({
    declarations: [FuseAlertDialogComponent],
    imports: [
      CommonModule,
      
      MatDialogModule,
      MatButtonModule,
      MatIconModule,
      IconModule
    ],
    //entryComponents: [	FuseAlertDialogComponent],
})
export class FuseAlertDialogModule{}
