import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';

import { FuseThemeOptionsModule } from '@fuse/components';
import { FuseSidebarComponent } from '@fuse/components/sidebar/sidebar.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { ChatPanelModule } from '@pages/chat-panel/chat-panel.module';
import { ContentModule } from 'app/layout/components/content/content.module';
import { FooterComponent } from 'app/layout/components/footer/footer.component';
//import { FooterModule } from 'app/layout/components/footer/footer.module';
import { NavbarModule } from 'app/layout/components/navbar/navbar.module';
import { QuickPanelModule } from 'app/layout/components/quick-panel/quick-panel.module';
import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
//import { ToolbarModule } from 'app/layout/components/toolbar/toolbar.module';

import { HorizontalLayout1Component } from 'app/layout/horizontal/layout-1/layout-1.component';

@NgModule({
	declarations: [
		HorizontalLayout1Component
	],
	imports: [
		MatSidenavModule,

		FuseSharedModule,
		FuseSidebarComponent,
		FuseThemeOptionsModule,
		ChatPanelModule,
		ContentModule,
		//FooterModule,
		FooterComponent,
		NavbarModule,
		QuickPanelModule,
		ToolbarComponent,
		//ToolbarModule,
	],
	exports: [
		HorizontalLayout1Component
	]
})
export class HorizontalLayout1Module {
}
