import { NgModule } from '@angular/core';
import { FabMenuComponent } from './fab-menu.component';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexLayoutModule } from '@angular/flex-layout/projects/libs/flex-layout/module';

@NgModule({
	declarations: [FabMenuComponent],
	imports: [
		CommonModule,
		MatButtonModule,
		MatIconModule,
		MatTooltipModule,
		FlexLayoutModule
	],
	exports: [FabMenuComponent]
})
export class FabMenuModule {
}
