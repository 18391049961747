import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Platform } from '@angular/cdk/platform';

import { FuseConfigService } from '@fuse/services/config.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DetectDeviceService } from '@shared/services/detectDevice.service';
import { BREAKPOINT_NAME } from '@shared/services/breakpoints.service';

@Component({
	selector: 'vertical-layout-1',
	templateUrl: './layout-1.component.html',
	styleUrls: ['./layout-1.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class VerticalLayout1Component implements OnInit, OnDestroy {
	fuseConfig: any;
	isMobile: boolean =false;

	// Private
	private _unsubscribeAll: Subject<any>;
	containerStyle: {
		'background': string;
		'background-image': string;
		'background-repeat': string;
		'background-size': string;
		'background-position': string;
	};

	/**
	 * Constructor
	 *
	 * @param {FuseConfigService} _fuseConfigService
	 */
	constructor(
		private _fuseConfigService: FuseConfigService,
		private _platform: Platform,
		private sanitizer: DomSanitizer,
		private _detectDevice: DetectDeviceService,
	) {

		// Set the private defaults
		this._unsubscribeAll = new Subject();
		// Set the defaults
		this._detectDevice.subscribeToLayoutChanges().subscribe(observerResponse => {
			if (this._detectDevice.isBreakpointActive(BREAKPOINT_NAME.mobile)) {
				this.isMobile = this._detectDevice.isBreakpointActive(BREAKPOINT_NAME.mobile) ? true : false;
			}
		});

		if (this.isMobile) {
			//Configure the layout
			this._fuseConfigService.config = {
				layout: {
					footer: {
						hidden: true
					}
				}
			};
		}
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		// Subscribe to config changes
		this._fuseConfigService.config
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((config) => {
				this.fuseConfig = config;
				if (this.fuseConfig.bgPage) {
					if (this.fuseConfig.bgPage) {
						this.containerStyle = {
							'background': !this.fuseConfig?.bgPage ? ' #ffffffd9' : 'transparent',	
							'background-image': 'url(' + this.fuseConfig?.bgPage + ')',
							'background-repeat': 'no-repeat',
							'background-position': 'center center',
							'background-size': 'cover',
						};
					}
				}
				//console.log( this.containerStyle );
			});

		// Check if is mobile
		if (this._platform.ANDROID || this._platform.IOS) {
			this.isMobile = true;
		}

		// Return if it's mobile
		if (this.isMobile) {
			// Return...
			return;
		}
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}
}
