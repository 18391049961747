import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { DomSanitizer } from '@angular/platform-browser';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
    selector     : 'vertical-layout-2',
    templateUrl  : './layout-2.component.html',
    styleUrls    : ['./layout-2.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VerticalLayout2Component implements OnInit, OnDestroy
{
	fuseConfig: any;
	isMobile: boolean = false;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
		private _fuseConfigService: FuseConfigService,
		private sanitizer: DomSanitizer,
		private breakpointObserver: BreakpointObserver,
    )
    {
        // Set the private defaults
		this._unsubscribeAll = new Subject();
		this.breakpointObserver.observe( [ '(max-width: 599px)' ] ).subscribe( ( state: BreakpointState ) => {
			this.isMobile = state.matches ? true : false;
		} );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
	containerStyle: any;
    ngOnInit(): void
	{
		this.containerStyle = {};
        
		// Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
				this.fuseConfig = config;

				if ( this.fuseConfig.bgPage ) {
					this.containerStyle = {
						'background': !this.fuseConfig?.bgPage ? ' #ffffffd9' : 'transparent',
						'background-image': 'url(' + this.fuseConfig?.bgPage + ')',
						'background-repeat': 'no-repeat',
						'background-position': 'center center',
						'background-size': 'cover',
					};
				}
            });
	}

	transform() {
		return this.sanitizer.bypassSecurityTrustResourceUrl( this.fuseConfig.bgPage );
	}

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }
}
