import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSidebarComponent } from '@fuse/components/sidebar/sidebar.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { ChatPanelModule } from '@pages/chat-panel/chat-panel.module';

import { ContentModule } from 'app/layout/components/content/content.module';
import { FooterComponent } from 'app/layout/components/footer/footer.component';
//import { FooterModule } from 'app/layout/components/footer/footer.module';
import { NavbarModule } from 'app/layout/components/navbar/navbar.module';
import { QuickPanelModule } from 'app/layout/components/quick-panel/quick-panel.module';
import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
//import { ToolbarModule } from 'app/layout/components/toolbar/toolbar.module';

import { VerticalLayout2Component } from 'app/layout/vertical/layout-2/layout-2.component';

@NgModule({
    declarations: [
        VerticalLayout2Component
    ],
    imports     : [
        RouterModule,
        FuseSharedModule,
		FuseSidebarComponent,
		ChatPanelModule,
        ContentModule,
		//FooterModule,
		FooterComponent,
        NavbarModule,
		QuickPanelModule,
		ToolbarComponent,
       // ToolbarModule
    ],
    exports     : [
        VerticalLayout2Component
    ]
})
export class VerticalLayout2Module
{
}
