import { Component, OnDestroy, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash-es';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { fuseAnimations } from '@fuse/animations';

import { PopoverService } from '@tinc/components/popover/popover.service';
import { MembershipService } from '@shared/services/membership/membership.service';
import { ToolbarUserDropdownComponent } from '@tinc/components/toolbar-user-dropdown/toolbar-user-dropdown.component';
import { LoginComponent } from '@pages/auth/login/login.component';
//
// import {ActivatedRoute,Router,NavigationEnd} from '@angular/router'
import { ApiService } from '@shared/services/api.service';
import { NotificationService } from '@shared/services/notification/notification.service';
import { environment } from '@environments/environment';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { AlertType } from '@shared/services/notification/alertType';
import { ImageResizer } from '@shared/services/imageResizer.service';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { WindowRefService } from '@shared/services/windowRef.service';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FuseSearchBarModule } from '@fuse/components';
import { TIncModule } from '@tinc/tinc.module';
import { IconModule } from '@tinc/components/icon/icon.module';
import { GlobalSearchResultsComponent } from '@tinc/components/globalSearchResults/globalSearchResults.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PopoverModule } from '@tinc/components/popover/popover.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseShortcutsComponent } from '@fuse/components/shortcuts/shortcuts.component';


declare var gtag: Function;
@Component( {
	selector: 'toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: [ './toolbar.component.scss' ],
	animations: fuseAnimations,
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ MatToolbarModule, CommonModule, FormsModule, FuseShortcutsComponent, TIncModule, IconModule, GlobalSearchResultsComponent, RouterModule, MatIconModule, MatButtonModule, FontAwesomeModule, PopoverModule, LoginComponent, FuseSharedModule, FuseSearchBarModule, MatMenuModule, MatDividerModule, MatTooltipModule ]
} )

export class ToolbarComponent implements OnInit, OnDestroy {
	@Input() mobileQuery: boolean;
	@Input() navigation: any;

	horizontalNavbar: boolean = false;
	rightNavbar: boolean = false;
	leftNavbar: boolean = false;
	hiddenNavbar: boolean = false;
	languages: any;
	selectedLanguage: any;
	userStatusOptions: any[];

	person: any = {};
	persona: any = {};
	verified: boolean;
	dropdownOpen: boolean;
	isLoggedIn: boolean;
	isMobile: boolean = false;
	isMobileLogoVisible = ( environment.features as any ).isMobileLogoVisible == null;
	//toolBarLogo = ( environment.features as any ).toolBarLogo;
	siteName = environment.siteName;
	fuseConfig: any;
	userProfile: any = {};

	foldStatus: boolean = true;
	// Global Search
	showResults: boolean;
	globalSearchLoading: boolean = false;
	searchResults: any = {};
	imagePrefix: string = new ImageResizer().prefix;
	@Input() currentUserFromService: any = {};
	fbURL: any;
	twtURL: any;
	faFacebook = faFacebookF;
	faTwitter = faTwitter;
	shareOnHidden: boolean = false;
	isOneNation: boolean = environment.siteName == "onenation";

	// Private
	private _unsubscribeAll: Subject<any>;

	constructor(
		private _fuseConfigService: FuseConfigService,
		private _fuseSidebarService: FuseSidebarService,
		private _popoverService: PopoverService,
		private _membership: MembershipService,
		private _changeDetectorRef: ChangeDetectorRef,
		private api: ApiService,
		private notify: NotificationService,
		private sanitizer: DomSanitizer,
		router: Router,
		private title4share: Title,
		_window: WindowRefService,
		private breakpointObserver: BreakpointObserver
	) {
		if ( localStorage.getItem( 'person' ) ) {
			this.isLoggedIn = true;
			this.person = JSON.parse( localStorage.getItem( 'person' ) );
			this.persona = JSON.parse( localStorage.getItem( 'person' ) ).persona;
			this.verified = JSON.parse( localStorage.getItem( 'person' ) ).verified;
			this._changeDetectorRef.markForCheck();
		}

		_membership.userChanged.subscribe( () => {
			this.isLoggedIn = _membership.isLoggedIn;
			this.person = _membership.currentUser;
			this.persona = _membership.persona;
			this.verified = _membership.verified;
			this.getUserProfile();
			this._changeDetectorRef.detectChanges();
		} );

		let pages = [ 'cms-admin' ];
		router.events.pipe( filter( event => event instanceof NavigationEnd ) )
			.subscribe( ( _ ) => {
				setTimeout( () => {
					this.shareOnHidden = false;
					for ( let i = 0; i < pages.length; i++ ) {
						if ( window.location.href.indexOf( pages[ i ] ) > -1 ) {
							this.shareOnHidden = true;
							break;
						}
					}

					let title = encodeURIComponent( this.title4share.getTitle() );
					let url = encodeURIComponent( window.location.href );
					// to run localhost only
					// let url = encodeURIComponent( window.location.href.replace( 'localhost:4200', 'mnsoftball.com' ) );




					// test page for localhost
					//let url = encodeURIComponent('https://mnsoftball.com/pages/event/detail/386783572');
					this.fbURL = `https://www.facebook.com/sharer/sharer.php?u=${ url }&t=${ title }`;
					this.twtURL = `https://twitter.com/share?text=${ title }&url=${ url }`;
					this._changeDetectorRef.detectChanges();
				}, 500 );
				// if ( _window.nativeWindow.googletag != null ) {
				// 	_window.nativeWindow.googletag.cmd.push( function () {
				// 		_window.nativeWindow.googletag.pubads().refresh();
				// 	} );
				// }
			} );

		// Set the private defaults
		this._unsubscribeAll = new Subject();
	}

	showResetImpersonate: boolean = false;
	toolBarLogoStyle: any;
	ipadPortrait: any;
	ipadLandscape: any;

	//if you wanted keep it updated on resize
	// @HostListener( 'window:resize', [ '$event' ] )
	// onResize( event ) {
	// 	this.isMobile = this.breakpointObserver.isMatched( '(max-width: 599px)' );
	// }

	//to get it on Init
	ngOnInit(): void {

		this.breakpointObserver.observe( [
			Breakpoints.Handset,
			Breakpoints.Tablet,
			Breakpoints.HandsetLandscape,
			Breakpoints.HandsetPortrait,
			Breakpoints.TabletLandscape,
			Breakpoints.TabletPortrait,
			Breakpoints.Web,
			Breakpoints.WebLandscape,
		] ).subscribe( ( state: BreakpointState ) => {
			//this.isMobile = state.matches ? true : false;
			if ( state.breakpoints[ '(max-width: 599px)' ] ) {
				this.isMobile = true;
			}
			if ( state.breakpoints[ Breakpoints.Handset ] ) {
				this.isMobile = true;
			}
			if ( state.breakpoints[ Breakpoints.Tablet ] ) {
				this.isMobile = true;
			}
			if ( state.breakpoints[ Breakpoints.HandsetLandscape ] ) {
				this.isMobile = true;
			}
			if ( state.breakpoints[ Breakpoints.HandsetPortrait ] ) {
				this.isMobile = true;
			}
			if ( state.breakpoints[ Breakpoints.TabletLandscape ] ) {
				this.isMobile = true;
			}
			if ( state.breakpoints[ Breakpoints.TabletPortrait ] ) {
				this.isMobile = true;
			}
			if ( state.breakpoints[ Breakpoints.Web ] || state.breakpoints[ Breakpoints.WebLandscape ] ) {
				this.isMobile = false;
			}

			this._changeDetectorRef.detectChanges();
		} );

		this.showResetImpersonate = localStorage.getItem( 'person0' ) != null;
		this.getUserProfile();

		// Subscribe to the config changes
		this._fuseConfigService.config.pipe( takeUntil( this._unsubscribeAll ) ).subscribe( {
			next: ( config ) => {
				this.fuseConfig = config;
				this.horizontalNavbar = config.layout.navbar.position === 'top';
				this.rightNavbar = config.layout.navbar.position === 'right';
				this.leftNavbar = config.layout.navbar.position === 'left';
				this.hiddenNavbar = config.layout.navbar.hidden === true;
				this._changeDetectorRef.detectChanges();
			},
			error: ( err ) => {
				this.notify.openAlertModal( ( err.error ? err.error : "An error has occured" ), AlertType.ERROR );
			}
		} );

	}

	getUserProfile() {
		if ( this.isLoggedIn ) {
			setTimeout( () => {
				this.userProfile = this._membership.getUserProfile();
			}, 100 );
		}
	}

	// to bind base64 image into html <img [src]="transform()">
	transform() {
		return this.sanitizer.bypassSecurityTrustResourceUrl( this.fuseConfig.layout.toolbar.toolBarLogo );
	}

	resetImpersonate() {
		if ( localStorage.getItem( 'person0' ) != null )
			localStorage.setItem( 'person', localStorage.getItem( 'person0' ) );
		localStorage.removeItem( 'person0' );
		var returnURL = localStorage.getItem( 'impersonateReturnURL' );
		localStorage.removeItem( 'impersonateReturnURL' );

		location.href = returnURL ?? '/pages/hq/support';
		setTimeout( () => {
			location.reload();
		}, 200 );
	}

	toggleSidebarOpened( key ): void {
		if ( key == 'navbar' ) {
			this.foldStatus = !this.foldStatus;
		}
		this._fuseSidebarService.getSidebar( key ).toggleOpen();
	}
	// toggleSidebarOpened(key?): void {		
	// 	this._fuseSidebarService.getSidebar( 'navbar' ).toggleOpen();
	// 	this.foldStatus = !this.foldStatus;
	// }


	toggleSidebarFolded(): void {
		this._fuseSidebarService.getSidebar( 'navbar' ).toggleFold();
		this.foldStatus = !this.foldStatus;
		this.fuseConfig.layout.navbar.folded = !this.foldStatus;
		this._fuseConfigService.setConfig( this.fuseConfig );
	}

	_keyword: string = null;
	search( keyword ): void {
		this._keyword = keyword;
	}

	closeSearchResults() {
		this.showResults = false;
	}

	openLogin( originLogin: any ) {
		// if in the mobile app do not bring pop over, instead trigger the native app's login screen due to the app store requirement
		if ( localStorage.getItem( 'mobileAppModeV2' ) != null )
			( window as any ).openLoginV2?.postMessage( '' );
		else
			this.showPopover( originLogin, 'login' );
	}

	showPopover( originRef: HTMLElement, page: string ) {
		this.dropdownOpen = true;
		this._changeDetectorRef.markForCheck();

		const popoverRef = this._popoverService.open( {
			content: ( page == 'myAccount' ? ToolbarUserDropdownComponent : LoginComponent ),
			origin: originRef,
			offsetY: 2,
			position: [
				{
					originX: 'center',
					originY: 'top',
					overlayX: 'center',
					overlayY: 'bottom'
				},
				{
					originX: 'end',
					originY: 'bottom',
					overlayX: 'end',
					overlayY: 'top',
				},
			]
		} );

		popoverRef.afterClosed$.subscribe( () => {
			this.dropdownOpen = false;
			this._changeDetectorRef.markForCheck();
		} );
	}

	reSendValidation() {
		this.api.get( "Membership/resendVerification/" + environment.siteName, true ).pipe( takeUntil( this._unsubscribeAll ) ).subscribe( {
			next: () => {
				this.notify.openToaster( "Your verification code is sent.", "success" );
			},
			error: ( _ ) => {
				this.notify.openToaster( "An error occured, please try again later.", "error" );
			}
		} );
	}

	recordEvent( site ) {
		gtag( 'event', 'shareOn', {
			'event_category': 'Share On',
			'event_label': 'Share On Click',
			'value': site
		} );
	}

	shareLink( socialSite: string | number ) {
		var shareUrl = null;
		switch ( socialSite ) {
			case "facebook":
				shareUrl = "https://www.facebook.com/sharer/sharer.php?u=";
				break;
			case "twitter":
				shareUrl = "https://twitter.com/intent/tweet?url=";
				break;
		}
		if ( shareUrl != null )
			window.open( shareUrl + encodeURI( window.location.href ) );
	}

	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next( true );
		this._unsubscribeAll.complete();
	}

	toggleSidebarOpen( key ): void {
		this._fuseSidebarService.getSidebar( key ).toggleOpen();
	}
}
