import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
    selector     : 'vertical-layout-3',
    templateUrl  : './layout-3.component.html',
    styleUrls    : ['./layout-3.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VerticalLayout3Component implements OnInit, OnDestroy
{
	fuseConfig: any;
	isMobile: boolean = false;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
		private _fuseConfigService: FuseConfigService,
		private breakpointObserver: BreakpointObserver,
    )
    {
		this.breakpointObserver.observe( [ '(max-width: 599px)' ] ).subscribe( ( state: BreakpointState ) => {
			this.isMobile = state.matches ? true : false;
		} );
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
	containerStyle: any;
    ngOnInit(): void
    {
		this.containerStyle = { 'background-color': 'white' };

		// Subscribe to config changes
		this._fuseConfigService.config
			.pipe( takeUntil( this._unsubscribeAll ) )
			.subscribe( ( config ) => {
				this.fuseConfig = config;


				if ( this.fuseConfig.bgPage ) { 
					this.containerStyle = {
						'background': !this.fuseConfig?.bgPage ? ' #ffffffd9' : 'transparent',
						'background-image': 'url(' + this.fuseConfig?.bgPage + ')',
						'background-repeat': 'no-repeat',
						'background-position': 'center center',
						'background-size': 'cover',
					};
				}
			} );
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }
}
